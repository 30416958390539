import React, { useEffect, useState } from "react";
import {
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { CLIENTS } from "../constants/routes";
import UnstyledLink from "./UnstyledLink";
import { useDispatch } from "react-redux";
import useAppState from "../redux/useAppState";
import { ClientModel } from "../models/Client";
import { fetchClients } from "../redux/clients/actions";

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const Clients = (props: Props) => {
  const dispatch = useDispatch();
  const { clients, loading, initialFetch } = useAppState(
    (state) => state.clients
  );

  const [filteredClients, setFilteredClients] = useState<ClientModel[]>([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (!loading && !initialFetch) {
      dispatch(fetchClients());
    }
  }, [loading, dispatch, initialFetch]);

  useEffect(() => {
    if (searchString === "") setFilteredClients(clients);
    else {
      let currentClients = clients.filter((value) => {
        return (
          value?.firstname
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()) ||
          value?.lastname
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        );
      });

      setFilteredClients(currentClients);
    }
  }, [setFilteredClients, searchString, clients]);

  useEffect(() => {
    props.setAppBarTitle("Lieferanten");
  }, [props]);

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Liste aller Lieferanten</Typography>
        <TextField
          id="standard-search"
          label="Suchen"
          type="search"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <List>
          {filteredClients.map((client) => (
            <ListItem>
              <ListItemText
                primary={client.lastname + " " + client.firstname}
                secondary={client.address + ", " + client.city}
              />
              <ListItemSecondaryAction>
                <UnstyledLink to={CLIENTS + "/" + client.id}>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </UnstyledLink>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Wrapper>
    </React.Fragment>
  );
};

export default Clients;
