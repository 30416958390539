import React, { useCallback, useEffect, useState } from "react";

import Navigation from "./Navigation";
import { Route } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import Login from "./Login";
import Dashboard from "./Dashboard";
import NewClient from "./NewClient";
import Clients from "./Clients";
import Client from "./Client";
import EditClient from "./EditClient";
import NewProductProcess from "./NewProduct/NewProductProcess";
import Products from "./Products";
import Product from "./Product";
import { createGlobalState } from "react-hooks-global-state";
import EditProduct from "./EditProduct";
import CategoryBrand from "./CategoryBrand";
import Labels from "./Labels";
import Firebase from "./Firebase/Firebase";
import Backup from "./Backup";
import Website from "./Website";

export const useProductState = createGlobalState<{
  categories: string[];
  brands: string[];
}>({ categories: [], brands: [] }).useGlobalState;

const LoggedIn = () => {
  let firebase = Firebase.getInstance();
  let [appBarTitle, setAppBarTitle] = useState("Übersicht");
  const [, setCategories] = useProductState("categories");
  const [, setBrands] = useProductState("brands");

  const fetchCategories = useCallback(() => {
    firebase?.doGetAllCategories().then((result) => {
      let myCategories: string[] = [];
      result?.forEach((doc: any) => {
        myCategories.push(doc.data().name);
      });
      setCategories(myCategories);
    });
  }, [firebase, setCategories]);

  const fetchBrands = useCallback(() => {
    firebase?.doGetAllBrands().then((result) => {
      let myBrands: string[] = [];
      result?.forEach((doc: any) => {
        myBrands.push(doc.data().name);
      });
      setBrands(myBrands);
    });
  }, [firebase, setBrands]);

  useEffect(() => {
    fetchBrands();
    fetchCategories();
  }, [fetchBrands, fetchCategories]);

  return (
    <React.Fragment>
      <Navigation title={appBarTitle} />
      <Route
        path={ROUTES.DASHBOARD}
        exact
        render={() => (
          <Dashboard
            setAppBarTitle={(title: string) => setAppBarTitle(title)}
          />
        )}
      />
      <Route
        path={ROUTES.LOGGEDIN}
        exact
        render={() => (
          <Dashboard
            setAppBarTitle={(title: string) => setAppBarTitle(title)}
          />
        )}
      />
      <Route
        path={ROUTES.NEWCLIENT}
        render={() => (
          <NewClient
            setAppBarTitle={(title: string) => setAppBarTitle(title)}
          />
        )}
      />
      <Route
        path={ROUTES.CLIENTS}
        exact
        render={() => (
          <Clients setAppBarTitle={(title: string) => setAppBarTitle(title)} />
        )}
      />
      <Route
        path={ROUTES.CLIENTS + "/:id"}
        exact
        render={() => (
          <Client setAppBarTitle={(title: string) => setAppBarTitle(title)} />
        )}
      />
      <Route
        path={ROUTES.CLIENTS + "/:id/edit"}
        render={() => (
          <EditClient
            setAppBarTitle={(title: string) => setAppBarTitle(title)}
          />
        )}
      />
      <Route
        path={ROUTES.NEWPRODUCT}
        render={() => (
          <NewProductProcess
            setAppBarTitle={(title: string) => setAppBarTitle(title)}
          />
        )}
      />
      <Route
        path={ROUTES.PRODUCTS}
        exact
        render={() => (
          <Products setAppBarTitle={(title: string) => setAppBarTitle(title)} />
        )}
      />
      <Route
        path={ROUTES.PRODUCTS + "/:id"}
        exact
        render={() => (
          <Product setAppBarTitle={(title: string) => setAppBarTitle(title)} />
        )}
      />
      <Route
        path={ROUTES.PRODUCTS + "/:id/edit"}
        render={() => (
          <EditProduct
            setAppBarTitle={(title: string) => setAppBarTitle(title)}
          />
        )}
      />
      <Route
        path={ROUTES.LABELS}
        exact
        render={() => (
          <Labels setAppBarTitle={(title: string) => setAppBarTitle(title)} />
        )}
      />
      <Route
        path={ROUTES.CATEGORYBRAND}
        render={() => (
          <CategoryBrand
            setAppBarTitle={(title: string) => setAppBarTitle(title)}
          />
        )}
      />
      <Route
        path={ROUTES.WEBSITE}
        render={() => (
          <Website setAppBarTitle={(title: string) => setAppBarTitle(title)} />
        )}
      />
      <Route
        path={ROUTES.BACKUP}
        render={() => (
          <Backup setAppBarTitle={(title: string) => setAppBarTitle(title)} />
        )}
      />
      <Route path={ROUTES.LOGIN} component={Login} />
    </React.Fragment>
  );
};
export default LoggedIn;
