import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import ProductCard from "../views/ProductCard";
import { ProductModel, ProductStates } from "../models/Product";
import useAppState from "../redux/useAppState";
import { useDispatch } from "react-redux";
import { fetchLabelProducts } from "../redux/products/actions";

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const Labels = (props: Props) => {
  const dispatch = useDispatch();

  const { products, initialFetchLabel, loading } = useAppState(
    (state) => state.products
  );

  const labelProducts = useMemo(
    () => products.filter((p) => p.state === ProductStates.REGISTERED),
    [products]
  );

  const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);
  const [searchString, setSearchString] = useState("");
  const [dataModalOpen, setDataModalOpen] = React.useState(false);

  useEffect(() => {
    if (!loading && !initialFetchLabel) dispatch(fetchLabelProducts());
  }, [loading, initialFetchLabel, dispatch]);

  useEffect(() => {
    if (searchString === "") setFilteredProducts(labelProducts);
    else {
      let currentProducts = labelProducts.filter((value) => {
        return (
          value?.id
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()) ||
          value?.brand
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()) ||
          value?.title
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        );
      });
      setFilteredProducts(currentProducts);
    }
  }, [setFilteredProducts, searchString, products, labelProducts]);

  useEffect(() => console.log(filteredProducts), [filteredProducts])

  useEffect(() => {
    props.setAppBarTitle("Etiketten");
  }, [props]);

  return (
    <React.Fragment>
      <Wrapper>
        <Button onClick={() => setDataModalOpen(true)}>Etiketten Daten</Button>
        <Typography variant="h6">Liste aller Produkte</Typography>
        <TextField
          id="standard-search"
          label="Suchen"
          type="search"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <List>
          {console.log(filteredProducts)}
          {filteredProducts.map((value, index) => (
            <ProductCard key={index} product={value} />
          ))}
        </List>
      </Wrapper>
      <Dialog
        open={dataModalOpen}
        onClose={() => setDataModalOpen(false)}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Etiketten Daten</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TableContainer style={{ marginBottom: 20 }}>
              <Table>
                <TableBody>
                  {labelProducts.map((value) => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">{value.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{value.size}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {value.externalPrice?.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{value.id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{value.brand}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Labels;
