import React, {useEffect, useMemo, useState} from "react";
import { Container, List, TextField, Typography } from "@material-ui/core";
import styled from "styled-components";
import ProductCard from "../views/ProductCard";
import useAppState from "../redux/useAppState";
import { useDispatch } from "react-redux";
import { fetchProducts } from "../redux/products/actions";
import {ProductModel, ProductStates} from "../models/Product";

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const Products = (props: Props) => {
  const dispatch = useDispatch();

  const { products, loading, initialFetch } = useAppState(
    (state) => state.products
  );
  const storeProducts = useMemo(
    () => products.filter((p) => p.state === ProductStates.STORE),
    [products]
  );

  const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (!loading && !initialFetch)
      dispatch(fetchProducts());
  }, [loading, initialFetch, dispatch]);

  useEffect(() => {
    if (searchString === "") setFilteredProducts(storeProducts);
    else {
      let currentProducts = storeProducts.filter((value) => {
        return (
          value?.id
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()) ||
          value?.brand
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()) ||
          value?.title
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        );
      });
      setFilteredProducts(currentProducts);
    }
  }, [setFilteredProducts, searchString, storeProducts]);

  useEffect(() => {
    props.setAppBarTitle("Produkte");
  }, [props]);

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Liste aller Produkte</Typography>
        <TextField
          id="standard-search"
          label="Suchen"
          type="search"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <List>
          {filteredProducts.map((value, index) => (
            <ProductCard key={index} product={value} />
          ))}
        </List>
      </Wrapper>
    </React.Fragment>
  );
};

export default Products;
